import { useEffect, useContext } from 'react';
import alanBtn from '@alan-ai/alan-sdk-web';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
  selectGenreOrCategory,
  searchMovie,
} from '../features/currentGenreOrCategory';
import { ColorModeContext } from '../utils/ToggleColorMode';
import { fetchToken } from '../utils';

const useAlan = () => {
  const { setMode } = useContext(ColorModeContext);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    alanBtn({
      key: '2bf316016092665e5d580ed4dd5c70902e956eca572e1d8b807a3e2338fdd0dc/stage',
      onCommand: (commandData) => {
        console.log('commandData:', commandData);
        if (commandData.command === 'chooseGenre') {
          const foundGenre = commandData.genres.find(
            (g) => g.name.toLowerCase() === commandData.genreOrCategory.toLowerCase(),
          );

          if (foundGenre) {
            history.push('/');
            dispatch(selectGenreOrCategory(foundGenre.id));
          } else {
            const category = commandData.genreOrCategory.startsWith('top')
              ? 'top_rated'
              : commandData.genreOrCategory;
            history.push('/');
            dispatch(selectGenreOrCategory(category));
          }
        } else if (commandData.command === 'changeMode') {
          if (commandData.mode === 'light') {
            console.log('light');
            setMode('light');
          } else {
            setMode('dark');
          }
        } else if (commandData.command === 'login') {
          fetchToken();
        } else if (commandData.command === 'logout') {
          localStorage.clear();
          history.push('/');
        } else if (commandData.command === 'search') {
          dispatch(searchMovie(commandData.query));
        }
      },
      onError: (error) => {
        console.error('Alan SDK Error:', error);
      },
      onConnectionStatus: (status) => {
        console.log('Alan SDK Connection Status:', status);
        // You can handle different connection statuses here
      },
    });
  }, []);
};

export default useAlan;
